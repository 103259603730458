<template>
  <div class="user-tab-permission">
    <v-card class="mb-7">
      <v-card-title>
        Permission
      </v-card-title>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Module
              </th>
              <th class="text-left">
                Read
              </th>
              <th class="text-left">
                Write
              </th>
              <th class="text-left">
                Update
              </th>
              <th class="text-left">
                Delete
              </th>
            </tr>
          </thead>
          <tbody class="">
            <tr
              v-for="item in subjects"
              :key="item"
            >
              <td>{{ item }}</td>
              <td>
                <v-checkbox
                  v-model="abilities[item.toLowerCase()]['read']"
                  @change="handleAbilities($event,item.toLowerCase(),'read')"
                ></v-checkbox>
              </td>
              <td>
                <v-checkbox
                  v-model="abilities[item.toLowerCase()]['write']"
                  @change="handleAbilities($event,item.toLowerCase(),'write')"
                ></v-checkbox>
              </td>
              <td>
                <v-checkbox
                  v-model="abilities[item.toLowerCase()]['update']"
                  @change="handleAbilities($event,item.toLowerCase(),'update')"
                ></v-checkbox>
              </td>
              <td>
                <v-checkbox
                  v-model="abilities[item.toLowerCase()]['delete']"
                  @change="handleAbilities($event,item.toLowerCase(),'delete')"
                ></v-checkbox>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import { mdiLockOutline } from '@mdi/js'

export default {
  data() {
    return {
      subjects: ['Company', 'Student', 'School', 'User', 'Province', 'Regency', 'District', 'Village'],
      abilities: {
        company: {
          read: false,
          write: false,
          update: false,
          delete: false,
        },
        student: {
          read: false,
          write: false,
          update: false,
          delete: false,
        },
        school: {
          read: false,
          write: false,
          update: false,
          delete: false,
        },
        user: {
          read: false,
          write: false,
          update: false,
          delete: false,
        },

        province: {
          read: false,
          write: false,
          update: false,
          delete: false,
        },
        regency: {
          read: false,
          write: false,
          update: false,
          delete: false,
        },
        district: {
          read: false,
          write: false,
          update: false,
          delete: false,
        },
        village: {
          read: false,
          write: false,
          update: false,
          delete: false,
        },
      },
      userAbilities: [],
      ability: {
        subject: '',
        action: '',
      },
      icons: {
        mdiLockOutline,
      },
      user: {},
    }
  },
  async mounted() {
    this.user = JSON.parse(localStorage.getItem('user')).user
    this.userAbilities = JSON.parse(localStorage.getItem('userAbility'))
    await this.getUserAbilities()
    this.isCheckedPermission()
  },
  methods: {
    async getUserAbilities() {
      await this.$services.ApiServices.get(this.$route.params.uuid).then(
        ({ data }) => {
          this.userAbilities = data.data.ability
        },
        err => console.error(err),
      )
    },
    isCheckedPermission() {
      this.userAbilities.forEach(item => {
        this.abilities[item.subject][item.action] = true
      })
    },
    async handleAbilities(event, subject, action) {
      if (event) {
        await this.$services.ApiServices.add('ability', {
          users_uuid: this.user.uuid,
          subject,
          action,
        }).then(
          ({ data }) => this.userAbilities.push(data.data),
          err => console.error(err),
        )
      } else {
        const abilitiesUuid = this.userAbilities.filter(item => item.subject === subject && item.action === action)
        await this.$services.ApiServices.destroy('ability', abilitiesUuid)
      }
    },
  },
}
</script>

<style>
</style>
